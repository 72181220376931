<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_green_logo_horizontal.svg"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen"  style="height:80vh;">
      <div class="m-auto">

        <img
          src="@/assets/images/relatus_green_logo.svg"
          class="m-auto"
          style="height: 100px;"
        >

        <div style="margin-top: 30px;">
          <h3 class="text text-center">
            Sign In
          </h3>

          <p class="text text-center">
            New to Relatus? <router-link :to="{name: 'registration.signup'}" class="underline" style="color: #377CE1;">Get Started Now.</router-link>
          </p>
        </div>

        <form
          class="mt-10 mb-20"
          @submit.prevent="register"
        >
          <div
            v-if="errorMessage"
            class="flex items-center text-dark text-sm mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-3">
            <input
              v-model="username"
              type="text"
              class="form-control"
              placeholder="Username or Email"
              required
              style='margin-right: 20px;'
            >

            <input
              v-model="password"
              type="text"
              class="form-control"
              placeholder="Password"
              required
              style='margin-left: 20px;'
            >
          </div>

          <div class="mb-3">
            <router-link
              :to="{name: 'registration.reset-password'}"
              class="underline"
              style="color: #8B91A0;margin-left: 55%;"
            >
              Forgot Password
            </router-link>
          </div>

          <p class="text-dark text-center mt-8">
            -or join with-
          </p>

          <ul class="flex justify-center mt-6">
            <li class="mr-5">
              <a
                href="#"
              ><img
                src="@/assets/images/ic_linkedin_2.svg"
                class="social-links"
                alt="Sign in with LinkedIn"
              ></a>
            </li>
            <li class="mr-5">
              <a
                href="#"
              ><img
                src="@/assets/images/ic_google_2.svg"
                class="social-links"
                alt="Sign in with Google"
              ></a>
            </li>
            <li>
              <a
                href="#"
              ><img
                src="@/assets/images/ic_twitter_2.svg"
                class="social-links"
                alt="Sign in with Twitter"
              ></a>
            </li>
          </ul>

          <div class="mt-7 text-center">
            <router-link
              :to="{name: 'registration.signin.confirmation'}"
              class="btn btn-primary"
              style="background-color: #83C03B!important;border-color: #83C03B!important;"
            >
              Sign In
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RegistrationSignin',
  data() {
    return {
      username: "",
      password: "",
      // errorMessage: "Hmm, looks like either your username and/or password are incorrect. Please try again."
      errorMessage: null
    };
  },
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 40px;
  color: #1E1E1E;
  letter-spacing: 1px;
  font-weight: 700;
}
.sign-up p {
  font-family: 'Helvetica';
  font-size: 24px;
  line-height: 30px;
  color: #1E1E1E;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
input {
  background: #F9F9F9!important;
  color: #83C03B!important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  min-width: 250px;
}
input::placeholder {
  color: #83C03B!important;
  opacity: 1!important;
}
input:-ml-input-placeholder {
  color: #83C03B!important;
}
input::-ml-input-placeholder {
  color: #83C03B!important;
}
.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.alert-error {
  background: rgba(217, 54, 31, 0.3);
  border-radius: 10px;
  padding: 10px;
}
.alert-error p {
  font-size: 14px;
}
</style>
